<template>
  <div>
    <b-modal v-model="openGoogleIframe" scrollable centered size="xl"
      :header-class="['py-2', 'bg-dagee']" :body-class="['p-0']"
      :footer-class="['p-0', 'bg-white']">
      <template v-slot:modal-header="{ close }">
        <div class="w-100 d-flex align-items-center justify-content-between">
          <div class="flex-fill">
            <b>iframe 編輯</b>
          </div>
          <div @click="close()">
            <font-awesome-icon :icon="['fas', 'times']" size="lg" />
          </div>
        </div>
      </template>

      <template v-slot:default='{close}'>
        <div class="p-2">
          <b-form-group label="Iframe Html">
            <!-- <b-form-input class="px-2" v-model="pointData.iframe" trim
              placeholder="請輸入 Iframe html">
            </b-form-input> -->

            <b-form-textarea v-model="pointData.iframe" placeholder="請輸入 Iframe html" rows="3"
              max-rows="6"></b-form-textarea>
          </b-form-group>
        </div>
      </template>

      <template v-slot:modal-footer='{close}'>
        <div class="w-100 d-flex align-content-center justify-content-around">
          <button class="btn btn-sm bg-dagee text-white px-4" @click="updateElement(close)">
            <b>確定</b>
          </button>

          <button class="btn btn-sm btn-danger px-4" @click="close">
            <b>取消</b>
          </button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
  import ctrl from "./js/UpdateGoogleIframe"
  export default ctrl

</script>
