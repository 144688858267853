export default {
  data() {
    return {

    }
  },

  computed: {
    openGoogleIframe: {
      get() {
        return this.$props.open;
      },

      set(data) {
        this.$emit("update:open", data);
      }
    },
  },

  props: {
    open: {
      default: false,
      type: Boolean,
    },

    pointData: {
      type: Object
    }
  },

  methods: {
    updateElement(close) {
      this.$store.dispatch("schedule/updateElement", this.pointData).then((data) => {
        close();
      });
    }
  }
}
